import { navigate } from "gatsby"
import React, { memo, useState, useEffect, useRef, useCallback } from "react"
import {
  Marker,
  Geography,
  Geographies,
  ComposableMap,
} from "react-simple-maps"
import geoJson from "../../../utils/provincias.json"
import IOreja from "../../images/quilmesMusica/svg/quilmes-web-09.svg"
import Bowser from "bowser"
const geoUrl = geoJson

const pathCordsProvince = {
  rio_negro: {
    x: 300,
  },
  buenos_aires: {
    x: 170,
  },
  neuquen: {
    x: 350,
  },
  san_juan: {
    x: 350,
  },
  cordoba: {
    x: 200,
  },
  jujuy: {
    x: 200,
  },
}

const Map = ({ eventos, isMobile, setSelectedProvince }) => {
  const provinceWithEvents = {
    default: {
      fill: "#67E9DB",
      stroke: "#67E9DB",
      strokeWidth: 1,
      outline: "none",
      transition: "all 250ms",
      cursor: "default",
    },
    hover: {
      fill: "white",
      stroke: "#67E9DB",
      strokeWidth: 1,
      outline: "none",
      transition: "all 250ms",
      cursor: "default",
    },
    pressed: {
      fill: "#67E9DB",
      stroke: "#67E9DB",
      strokeWidth: 1,
      outline: "none",
      transition: "all 250ms",
      cursor: "default",
    },
  }
  const provinceNoEvents = {
    default: {
      fill: "transparent",
      stroke: "#67E9DB",
      strokeWidth: 1,
      outline: "none",
      transition: "all 250ms",
    },
    hover: {
      fill: "transparent",
      stroke: "#67E9DB",
      strokeWidth: 1,
      outline: "none",
      transition: "all 250ms",
    },
    pressed: {
      fill: "transparent",
      stroke: "#67E9DB",
      strokeWidth: 1,
      outline: "none",
      transition: "all 250ms",
    },
  }

  const [provinciaSeleccionada, setProvinciaSeleccionada] = useState("")
  const [provinceName, setProvinceName] = useState("")
  const [urlParam, setUrlParam] = useState("")
  const [cordX, setCordX] = useState("")
  const [cordY, setCordY] = useState("")
  const [utilY, setUtilY] = useState(0)
  const [utilX, setUtilX] = useState(0)
  const [filter, setFilter] = useState("")

  const [pathX, setPathx] = useState("200")
  const [pathYMobile, setPathyMobile] = useState("95")
  const [pathY, setPathy] = useState("66")
  const [browserName, setBrowserName] = useState("")
  useEffect(() => {
    const {
      parsedResult: {
        browser: { name },
      },
    } = Bowser.getParser(window.navigator.userAgent)
    setBrowserName(name)
  })

  const styleMapButtonText = {
    font: isMobile ? `normal 48px Gothic` : `bold 24px Gothic`,
    fill: "white",
    fontVariant: "smallCaps",
  }

  const styleMapButtonButton = {
    font: isMobile ? `normal 36px Gothic` : `bold 16px Gothic`,
    fill: "#67E9DB",
  }


  const handlerClickBA = geo => {
    if (
      eventos.filter(event => {
        return event.provincia === geo.properties.NAME
      }).length >= 1
    ) {
    }
    if (provinciaSeleccionada) {
      setProvinciaSeleccionada(null)
    }
    let [y, x] = geo.geometry.coordinates[0][0]
    if (geo.geometry.type === "MultiPolygon") {
      ;[y, x] = geo.geometry.coordinates[0][0][0]
    }

    const province = geo.properties.ID_1
    const name = geo.properties.NAME
    const format = name
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .replace(/[\s]/g, "_")
    setProvinceName(name)
    setUrlParam(format)
    setSelectedProvince(province)
    setProvinciaSeleccionada(province)
    setCordX(x)
    setCordY(y)
    setUtilX(geo.properties.UTIL_X)
    setUtilY(geo.properties.UTIL_Y)
    setFilter(geo.properties.NAME)

    setPathx(pathCordsProvince[format].x)
    if (browserName === "Firefox") {
      setPathyMobile("107")
      setPathy("70")
    }
  }

  function handleClick(event) {
    setProvinciaSeleccionada(null)
  }

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        position: "relative",
      }}
    >
      <ComposableMap
        projectionConfig={{
          scale: isMobile ? 1500 : 900,
          rotate: [60, 0, -3],
          center: [-5, -37],
        }}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          height: "100%",
          width: "100%",
        }}
      >
        <Geographies geography={geoUrl} disableOptimization>
          {({ geographies }) =>
            geographies.map(geo => {
              const id = geo.properties.ID_1
              // const isClicked = provinciaSeleccionada === geo.properties.name
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onClick={() => {
                    if (
                      eventos.filter(event => {
                        return event.provincia === geo.properties.NAME
                      }).length >= 1
                    ) {
                      handlerClickBA(geo)
                      setProvinciaSeleccionada(
                        provinciaSeleccionada === id ? null : id
                      )
                    }
                  }}
                  style={
                    eventos.filter(event => {
                      return event.provincia === geo.properties.NAME
                    }).length >= 1
                      ? provinceWithEvents
                      : provinceNoEvents
                  }
                />
              )
            })
          }
        </Geographies>

        <Marker
          coordinates={[cordY - utilY, cordX - utilX]}
          fill="#777"
          onClick={() => handleClick()}
          width={60}
        >
          {provinciaSeleccionada ? (
            <>
              {isMobile ? (
                <image
                  href={IOreja}
                  height="100"
                  width="100"
                  x="0"
                  y="0"
                  onClick={() => handleClick()}
                />
              ) : (
                <image
                  href={IOreja}
                  height="50"
                  width="50"
                  x="0"
                  y="0"
                  onClick={() => handleClick()}
                />
              )}

              <line
                x1="38"
                x2={pathX - 10}
                y1="30"
                y2="32"
                stroke="white"
                stroke-width="2"
              />

              {isMobile ? (
                <text
                  x={pathX}
                  y="45"
                  font-variant="small-caps"
                  style={styleMapButtonText}
                >
                  {provinceName}
                </text>
              ) : (
                <text
                  x={pathX}
                  y="40"
                  font-variant="small-caps"
                  style={styleMapButtonText}
                >
                  {provinceName}
                </text>
              )}

              {isMobile ? (
                <a
                  onClick={() =>
                    navigate(`/quilmesfestivales/provincia?prov=${urlParam}`, {
                      state: {
                        data: eventos.filter(event => {
                          return event.provincia === filter
                        }),
                      },
                    })
                  }
                >
                  <path
                    d={`M${pathX},65 h180 a20,20 0 0 1 20,20 v20 a20,20 0 0 1 -20,20 h-180 a20,20 0 0 1 -20,-20 v-20 a20,20 0 0 1 20,-20 z`}
                    fill="none"
                    stroke="#67E9DB"
                    stroke-width="1"
                  />

                  <text
                    x={pathX}
                    y={pathYMobile}
                    fill="#FFFFFF"
                    alignment-baseline="middle"
                    font-variant="small-caps"
                    style={styleMapButtonButton}
                  >
                    EXPLORAR MÚSICA
                  </text>
                </a>
              ) : (
                <a
                  onClick={() =>
                    navigate(`/quilmesfestivales/provincia?prov=${urlParam}`, {
                      state: {
                        data: eventos.filter(event => {
                          return event.provincia === filter
                        }),
                      },
                    })
                  }
                >
                  <path
                    d={`M${pathX},50 h80 a10,10 0 0 1 10,10 v10 a10,10 0 0 1 -10,10 h-80 a10,10 0 0 1 -10,-10 v-10 a10,10 0 0 1 10,-10 z`}
                    fill="none"
                    stroke="#67E9DB"
                    stroke-width="1"
                  />
                  <text
                    x={pathX}
                    y={pathY}
                    fill="#FFFFFF"
                    alignment-baseline="middle"
                    font-variant="small-caps"
                    style={styleMapButtonButton}
                  >
                    EXPLORAR MÚSICA
                  </text>
                </a>
              )}
            </>
          ) : null}
        </Marker>
      </ComposableMap>
    </div>
  )
}

export default memo(Map)
